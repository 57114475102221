.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* .dot-indicators {
  position: absolute;
  top: 86%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  margin-top: 20px;
} */

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

.card-container {
  position: relative;
  width: 100%;
  height: 300px;
  /* position: relative; */
  width: 100%;
  /* Gradient color */

  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  position: absolute;
  width: 800px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: left 0.3s;
  background-color: #f9e4a6;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.prev,
.next {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  color: white;
  background-color: #ffd700;
  border-color: #ffd700;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 50%; /* this makes the button circular */
  width: 50px; /* this sets the width and height of the button to be equal, making it a perfect circle */
  height: 50px;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.custom-button {
  color: white;
  background-color: white;
  border-color: #007bff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 50%; /* this makes the button circular */
  width: 50px; /* this sets the width and height of the button to be equal, making it a perfect circle */
  height: 50px;
}

.custom-button:hover {
  color: #212529;
  background-color: #0069d9;
  border-color: #0062cc;
}

/*  */

.text {
  font-size: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .text {
  font-size: 24px;
  margin: 0;
  padding: 0;
  text-align: center;
} */

@media (max-width: 600px) {
  .card {
    width: 500px;
    height: 300px;
  }
  .text {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .card {
    width: 400px;
    height: 300px;
  }
  .text {
    font-size: 14px;
  }
}
