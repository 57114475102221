.About {
  /* width: 100%; */
  height: 100vh;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  align-items: center;
  height: 100vh;
  width: 90%;
  margin: auto;
}
.about-content {
  background-color: rgba(0, 0, 0, 0.3);
  color: whitesmoke;

  margin: 50px 20px 20px 20px;
  padding: 20px;
  font-size: 18px;
  border-radius: 10px;
  height: 100%;
}

.download-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.playstore-img {
  width: 200px;
}
.appstore-img {
  width: 150px;
}

.about-heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-paragraph {
  font-size: 16px;
  line-height: 1.5;
}

/* On Mobile */
@media only screen and (max-width: 600px) {
  .about-container {
    width: 100%;
  }
  .about-content {
    margin: 20px 0px 0px 0px;
  }

  .download-button {
    flex-direction: row;
  }
  .playstore-img {
    width: 150px;
  }
  .appstore-img {
    width: 120px;
  }
}
