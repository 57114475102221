.custom-video {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-video {
  position: absolute;
  width: 800px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: left 0.3s;
  background-color: transparent !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.custom-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.custom-controls button {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.custom-controls button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.custom-controls button i {
  visibility: hidden;
}

@media (max-width: 600px) {
  .card-video {
    width: 500px;
    height: 350px;
  }
  .text {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .card-video {
    width: 400px;
    height: 300px;
  }
  .text {
    font-size: 14px;
  }
}
