/* Add background image at back */

/* remove links under from button */
a {
  text-decoration: none;

  /* color: #000; */
}

/* Button with golden text and black opacity container */
.button {
  background-color: rgba(0, 0, 0, 0.4);
  color: #f2c94c !important;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  width: 50%;
  /* max-width: 500px; */
  display: inline-block;
  font-size: 25px;
  margin: 5px;
  cursor: pointer;
  list-style: none;
}

.button > a {
  color: #f2c94c;
}

/* Button hover */
.button:hover {
  background-color: rgba(0, 0, 0, 0.607);
}

/* center from all side button-container */
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Create button at bottom ringht with same obove button style  */
.button-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  padding: 20px;
}
